
import {
  defineComponent,
  reactive,
  onMounted,
  ref,
  watch,
  nextTick,
  inject,
  onUpdated
} from "vue";

import Loading from "@/components/UI/Loading";
import Preview from "@/components/UI/Preview";
import { ImgItem } from "@/components/UI/Preview/src/Preview.vue";
import { useRoute, useRouter } from "vue-router";
import $, { param } from "jquery";
import axios from "@/api/axios";
import dateFormat from "@/utils/dateFormat";
import qs from "qs";
import Toast from "@/components/UI/Toast";
import asyncLoadJs from "@/hooks/useAsyncLoadJs";
import Modal from "@/components/UI/Modal";

export default defineComponent({
  name: "about",
  components: {
    Loading,
    Preview,
  },

  setup() {
    const route = useRoute();
    const router = useRouter();
    const id = ref(route.query.newsid);
    const news = ref(null);
    const loading = ref(true);

    function getRenderData(id: string) {
      news.value = null;
      loading.value = true;
      axios
        .get("/M/News/NewsDetail/" + id)
        .then(res => {
          if (res.data.success) {
            const data = res.data.obj;
            news.value = data;
            document.title = data.newTitle + "-北纳生物";
            loading.value = false;
            route.meta.title = data.newTitle;
            route.meta.content.keywords = data.newskey;
            route.meta.content.description = data.newssummary;
            asyncLoadJs(
              "//imgcache.qq.com/open/qcloud/video/tcplayer/lib/hls.min.0.8.8.js",
              "about"
            ).then(() => {
              asyncLoadJs(
                "//imgcache.qq.com/open/qcloud/video/tcplayer/tcplayer.min.js",
                "about"
              ).then(() => {
                nextTick(() => {
                  data.newContent.match(/<script.*?>([\s\S]+?)<\/script>/gim);
                  eval(RegExp.$1);
                });
              });
            });
          } else {
            Toast({
              type: "error",
              title: res.data.msg
            });
          }
        })
        .catch(err => {
          console.log(err);
          loading.value = false;
        });
    }

    getRenderData(id.value as string);
    const loadOption = {
      text: "加载中...",
      color: "#df0024",
      textColor: "#df0024"
    };
    //  preview picture
    const imgList = reactive<ImgItem[]>([]);
    const currentIndex = ref(0);
    const previewing = ref(false);
    function getSrcIndex(src: string, array: JQuery<HTMLElement>): number {
      let index = 0;
      array.each(function(i, item) {
        if ($(this).prop("src") == src) {
          index = i;
        }
      });
      return index;
    }

    onMounted(() => {
      $(document).on("click", ".news-content img", function(e: Event) {
        const src = $(this).prop("src");
        currentIndex.value = getSrcIndex(src, $(".news-content img"));
        previewing.value = true;
      });
    });
    function closePreview() {
      previewing.value = false;
    }
    watch(
      () => {
        return news.value;
      },
      () => {
        nextTick(() => {
          const imgDom = document.querySelectorAll(".news-content img");
          imgDom.forEach(img => {
            imgList.push({ src: img.getAttribute("src") as string });
          });
        });
      }
    );

    watch(
      [
        () => {
          return route.query;
        },
        () => {
          return route.params;
        }
      ],
      (to, from) => {
        console.log(to, from);
        // 避免登录时触发页面刷新
        if (to[0].newsid && !from[1].valid) {
          getRenderData(to[0].newsid as string);
        }
      }
    );
    const my = inject("userInfo") as any;
    return {
      news,
      dateFormat,
      loadOption,
      imgList,
      previewing,
      currentIndex,
      loading,
      closePreview,
      id,
    };
  }
});
